import { IconLoader2 } from "@tabler/icons-react";
import React, { useState } from "react";
import { cn } from "../lib/utils";
import { Input } from "./ui/Input";
import { Label } from "./ui/Label";
import { RainbowButton } from "./ui/RainbowButton";

export function Waitlist() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    // Debug form values directly
    const form = e.currentTarget;
    const nameInput = form.elements.namedItem("name") as HTMLInputElement;
    const emailInput = form.elements.namedItem("email") as HTMLInputElement;

    console.log("Direct values:", {
      name: nameInput?.value,
      email: emailInput?.value,
    });

    const formData = new FormData(form);
    console.log("FormData entries:", Object.fromEntries(formData.entries()));

    try {
      const response = await fetch(
        "https://app.gettaptap.com/v1/waitlist/join",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit");
      }

      setIsSubmitted(true);
    } catch (error) {
      console.error("Failed to submit:", error);
      // Optionally handle error state here
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-lg w-full mx-auto rounded-md md:rounded-2xl p-4 md:py-8 md:px-16 lg:px-20 lg:py-12 shadow-input bg-white dark:bg-black drop-shadow-xl">
      <h2 className="font-bold text-2xl md:text-4xl text-neutral-600 dark:text-neutral-200">
        <svg
          className="w-5 h-5 mb-2 md:w-8 md:h-8 md:mb-3 mr-2 inline-block"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.772 5.55532C14.0136 5.55532 12.5739 6.61293 11.6534 6.61293C10.6739 6.61293 9.39936 5.62505 7.86521 5.62505C4.95031 5.62505 2 7.99595 2 12.4589C2 15.2481 3.08571 18.1884 4.44285 20.0828C5.59937 21.6867 6.61427 23 8.07763 23C9.51737 23 10.1546 22.0586 11.9484 22.0586C13.7658 22.0586 14.1788 22.9767 15.772 22.9767C17.3534 22.9767 18.4037 21.5473 19.4068 20.1409C20.5161 18.5255 20.9882 16.9565 21 16.8752C20.9055 16.852 17.8844 15.6316 17.8844 12.2263C17.8844 9.27437 20.2564 7.94946 20.3981 7.84486C18.8285 5.62505 16.4329 5.55532 15.772 5.55532ZM14.9459 3.67256C15.6658 2.81253 16.1732 1.6387 16.1732 0.453258C16.1732 0.290551 16.1615 0.127842 16.1378 0C14.9578 0.0464881 13.5416 0.767053 12.7037 1.7433C12.031 2.48711 11.4174 3.67256 11.4174 4.858C11.4174 5.04395 11.4528 5.21829 11.4646 5.27639C11.5354 5.28802 11.6534 5.31126 11.7832 5.31126C12.8335 5.31126 14.1553 4.61394 14.9459 3.67256Z"
            fill="currentColor"
          ></path>
        </svg>
        Get MacOS App
      </h2>
      {/* <p className="text-neutral-600 text-sm max-w-sm mt-2 dark:text-neutral-300">
        Login to aceternity if you can because we don&apos;t have a login flow
        yet
      </p> */}
      {isSubmitted ? (
        <p className="text-neutral-600 text-md max-w-sm mt-2 dark:text-neutral-300">
          Thanks! We&apos;ll be in touch soon.
        </p>
      ) : (
        <form className="mt-4" onSubmit={handleSubmit}>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4">
            <LabelInputContainer>
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                name="name"
                required
                placeholder="John Smith"
                type="text"
                disabled={isLoading}
              />
            </LabelInputContainer>
          </div>
          <LabelInputContainer className="mb-4">
            <Label htmlFor="email">Email Address</Label>
            <Input
              id="email"
              name="email"
              placeholder="john@smith.com"
              type="email"
              disabled={isLoading}
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              title="Please enter a valid email address"
            />
          </LabelInputContainer>

          <RainbowButton
            className="mt-1 w-full text-base md:text-lg"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="flex items-center gap-2 justify-center flex-align-center w-full">
                <IconLoader2 className="animate-spin" /> Sending...
              </span>
            ) : (
              <>Sign Up</>
            )}
          </RainbowButton>
        </form>
      )}
    </div>
  );
}

const LabelInputContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};
